export const environment = {
  rcmHost: 'https://www.rcm.at',
  userPoolId: 'eu-central-1_7REapepQ1',
  sbToken: 'YO4EQVtjKNgOxsWwzRKTzgtt',
  authenticationPath: '/oauth2/authentication',
  storyblokSlug: 'cdn/datasource_entries',
  storyblokDataSourceLogin: 'loginpages',
  storyblokDataSourceFooter: 'footer',
  storyblokPortalSlug: 'portals/datasource',
};
